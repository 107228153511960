import React from 'react';
import { node, number } from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import Bold from '../Bold';

import colors from '../../utilities/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: colors.blue,
    borderBottomStyle: 'solid',
    marginBottom: 10,
  },
  text: {
    fontSize: 11,
    padding: '4 8',
    textTransform: 'uppercase',
  },
  tabText: {
    color: 'white',
    backgroundColor: colors.blue,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  titleText: {},
});

const TabHeader = ({ children, numberDays }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Bold style={{ ...styles.text, ...styles.tabText }}>
        {t('LAST_NUM_DAYS', { number: numberDays })}
      </Bold>
      <Bold style={{ ...styles.text, ...styles.titleText }}>{children}</Bold>
    </View>
  );
};

TabHeader.propTypes = {
  children: node.isRequired,
  numberDays: number,
};

TabHeader.defaultProps = {
  numberDays: 30,
};

export default TabHeader;
