import i18n from 'i18next';
import FetchBackend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

import { i18nConfig } from '../../src/services/locales';

i18n
  .use(FetchBackend)
  .use(initReactI18next)
  .init({
    ...i18nConfig,
    lng: 'en-US',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
