const TimeOfDay = require('./donut');
const DayOfWeek = require('./barChart');
const RescueUsage = require('./rescueChart');
const ControllerAdherence = require('./controllerArea');

const LoggerService = require('../services/logger');
const { logger = console } = LoggerService;

const CHART_TYPES = [
  'timeOfDay',
  'dayOfWeek',
  'rescueUsage',
  'controllerAdherence'
];

function createChart(data, window, i18n) {
  if (typeof window === 'undefined') {
    throw new Error('No window to operate on');
  }

  return type => {
    const builder = Reflect.construct(chartClass(type), [{ data, i18n }]);

    return builder.render(window).catch(err => {
      logger.debug && logger.debug({ msg: `error for type ${type}` });
      logger.error && logger.error(err);
      return null;
    });
  };
}

function chartClass(type) {
  switch (type) {
    case 'timeOfDay':
      return TimeOfDay;

    case 'dayOfWeek':
      return DayOfWeek;

    case 'rescueUsage':
      return RescueUsage;

    case 'controllerAdherence':
      return ControllerAdherence;

    default:
      logger.warn && logger.warn({ msg: `unknown chart type: ${type}` });
      return a => a;
  }
}

module.exports = function renderCharts({ data, window, i18n }) {
  return Promise.all(CHART_TYPES.map(createChart(data, window, i18n))).then(
    charts =>
      CHART_TYPES.reduce((coll, type, i) => {
        coll[type] = charts[i];
        return coll;
      }, {})
  );
};
