import React, { useContext } from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';

import Normal from '../Normal';
import Bold from '../Bold';
import LocaleContext from '../../context/locale';
import colors from '../../utilities/colors';

import { imageDirectory, getImgPath } from '../../images';

const styles = StyleSheet.create({
  container: {
    paddingBottom: 5,
    marginBottom: 10,
    flexDirection: 'row',
    borderBottomWidth: 5,
    borderBottomColor: colors.blue,
    borderBottomStyle: 'solid',
    alignItems: 'center',
  },
  logoColumn: {
    flexDirection: 'column',
    flexGrow: 0,
  },
  textColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  image: {
    height: 30,
    width: 108,
  },
  text: {
    marginBottom: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    fontSize: 10,
    textTransform: 'uppercase',
  },
});

const DocumentHeader = ({ firstLine, secondLine }) => {
  const locale = useContext(LocaleContext);
  const country = locale.split('-')[1];

  const image = country === 'JP' ? 'logo-jp.png' : 'logo.png';

  return (
    <View fixed style={styles.container}>
      <View style={styles.logoColumn}>
        <Image
          style={styles.image}
          safePath={imageDirectory}
          src={getImgPath(image)}
        />
      </View>
      <View style={styles.textColumn}>
        <Normal style={styles.text}>{firstLine}</Normal>
        <Bold style={styles.text}>{secondLine}</Bold>
      </View>
    </View>
  );
};

export default DocumentHeader;
