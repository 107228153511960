// rethink how we handle null data?

// much of the logic to place text on curves comes curtesy of
// https://www.visualcinnamon.com/2015/09/placing-text-on-arcs

// although I've since moved on from it fairly considerably

const moment = require('moment');

const {
  select: d3Select,
  scaleOrdinal,
  arc: d3Arc,
  pie: d3Pie
} = require('d3');

const Chart = require('../chartBase');
const colors = require('../../utilities/colors');
const { getD3HourFormat } = require('../../services/locales');

const hour6 = moment()
  .hour(6)
  .minute(0);
const hour12 = moment()
  .hour(12)
  .minute(0);
const hour17 = moment()
  .hour(17)
  .minute(0);
const hour22 = moment()
  .hour(22)
  .minute(0);

module.exports = class TimeOfDay extends Chart {
  buildData(data) {
    const trend = data.dailySummary.trends.timeofday || {};
    const locale = this.locale;
    const d3Locale = this.d3Locale;
    const formatter = d3Locale.format(getD3HourFormat(locale));

    const formatted6 = formatter(hour6);
    const formatted12 = formatter(hour12);
    const formatted17 = formatter(hour17);
    const formatted22 = formatter(hour22);

    return [
      {
        period: 'evening',
        value: trend.evening,
        color: '#BD5C1A',
        times: `${formatted17} - ${formatted22}`
      },
      {
        period: 'night',
        value: trend.night,
        color: '#4E2606',
        times: `${formatted22} - ${formatted6}`
      },
      {
        period: 'morning',
        value: trend.morning,
        color: '#FCDD7A',
        times: `${formatted6} - ${formatted12}`
      },
      {
        period: 'afternoon',
        value: trend.afternoon,
        color: '#FF8F36',
        times: `${formatted12} - ${formatted17}`
      },
      {
        period: 'none',
        value:
          trend.evening + trend.night + trend.morning + trend.afternoon === 0
            ? 100
            : 0,
        color: '#DDD',
        times: ''
      }
    ].filter(d => d.value); // 0 is falsy, remove ones with 0
  }

  type = 'timeOfDay';

  width = 500;

  height = 400;

  margins = {
    top: 20,
    bottom: 20,
    left: 90,
    right: 90
  };

  renderSVG(node) {
    const { width, height, margins, data } = this;

    const angleFromLimit = limit => angle => angle > -limit && angle < limit;

    const average = values => values.reduce((t, v) => t + v) / values.length;
    const midAngle = d => average([d.startAngle, d.endAngle]);

    const calculatePoint = (datum, radiusMult = 1) => {
      // Math.cos and Math.sin assume 0 is centered on 3 oclock,
      // but d3 centers on 12 oclock, so rotate 90 deg

      const mid = midAngle(datum) - Math.PI/2;
      const r = radius * radiusMult;

      const radX = Math.cos(mid) * r;
      const radY = Math.sin(mid) * r;

      return [radX, radY];
    };

    const halfWidth = (width - margins.top - margins.bottom) / 2;
    const halfHeight = (height - margins.left - margins.right) / 2;
    const radius = Math.min(halfWidth, halfHeight);

    const slice = d3Arc()
      .innerRadius(0)
      .outerRadius(radius);

    const arcs = d3Pie()
      .sort(null)
      .value(d => d.value)(data);

    const labelPos = radius * (2 / 3);

    const labelArc = d3Arc()
      .innerRadius(labelPos)
      .outerRadius(labelPos);

    const scale = scaleOrdinal(data.map(d => d.color));

    const svg = d3Select(node)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`)
      .attr('font-family', `"${this.fontFamily}", sans-serif`);

    svg
      .selectAll('.arc')
      .data(arcs)
      .enter()
      .append('path')
      .attr('class', 'arc')
      .attr('d', slice)
      .style('fill', d => scale(d.data.color))
      .style('stroke', 'rgba(0,0,0,0.1');

    svg
      .selectAll('.arc-label')
      .data(arcs)
      .enter()
      .append('text')
      .attr('class', 'arc-label')
      .attr('x', d => calculatePoint(d, 1.1)[0])
      .attr('y', d => calculatePoint(d, 1.1)[1])
      .attr('dy', d => {
        const c = midAngle(d);
        const test = angleFromLimit(Math.PI / 5);

        return test(c) ? '-.15em' : test(Math.PI - c) ? '.35em': 0;
      })
      .attr('fill', colors.text)
      .text(d => d.data.times)
      .style('font-size', '22px')
      .style('text-anchor', d => {
        const c = midAngle(d);
        const test = angleFromLimit(0.2);
        const shouldCenter = test(c) || test(Math.PI - c);

        return shouldCenter ? 'middle' : c < Math.PI ? 'start' : 'end';
      });

    svg
      .selectAll('.arc-percent')
      .data(arcs)
      .enter()
      .append('text')
      .attr('class', 'arc-percent')
      .attr('transform', d => `translate(${labelArc.centroid(d)})`)
      .attr('dy', '0.35em')
      .text(d =>
        d.data.period !== 'none' && d.data.value > 10 ? `${d.data.value}%` : ''
      )
      .attr('fill', '#FFF')
      .style('font-size', '24px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle');

    return node;
  }
};
