import React, { useContext } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import moment from 'moment';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import GreyText from '../GreyText';
import Bold from '../Bold';
import Normal from '../Normal';

import ErrorBoundary from '../ErrorBoundary';

import { displayDate } from '../../utilities/dates';
import LocaleContext from '../../context/locale';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  planItem: {
    flexDirection: 'column',
    width: '33.3333%',
    fontSize: 10,
    paddingRight: 10,
    marginBottom: 10,
  },
});

const scheduleText = (usageList, t, locale) => {
  if (!usageList || usageList.length === 0) return t('AS_NEEDED');

  const doses = usageList[0].doses;
  const times = usageList.map((u) =>
    displayDate(moment().hour(u.hour).minute(u.minute), locale, 'LT')
  );
  const s = times.length === 0 ? t('TAKEN_AS_NEEDED') : `(${times.join(', ')})`;

  return `${t('NUM_INHALATION', { count: doses })}, ${t('DAY_FREQUENCY', {
    frequency: times.length,
  })}, ${s}`;
};

const PlanItem = ({ item, style }) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  return (
    <View style={{ ...styles.planItem, ...style }}>
      <Bold>
        <GreyText>
          {t(`CURRENT_${item.medication.type.toUpperCase()}_MEDICATION`)}:
        </GreyText>
      </Bold>
      <Bold>{item.medication.name}</Bold>
      <Normal>{scheduleText(item.usageList, t, locale)}</Normal>
    </View>
  );
};

const medProps = shape({
  medication: shape({
    type: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  usageList: arrayOf(
    shape({
      doses: number,
      hour: number,
      minute: number,
    })
  ),
});

PlanItem.propTypes = {
  item: medProps.isRequired,
  style: shape({}),
};

PlanItem.defaultProps = {
  style: {},
};

const PatientPlan = ({ medications }) => {
  const meds = [].concat(medications.rescue, medications.controller);
  const itemStyle = { flexGrow: meds.length > 2 ? 0 : 1 };

  return (
    <View style={styles.container}>
      <ErrorBoundary>
        {meds.map((m) => (
          <PlanItem item={m} key={m.medicationId} style={itemStyle} />
        ))}
      </ErrorBoundary>
    </View>
  );
};

PatientPlan.propTypes = {
  medications: shape({
    controller: arrayOf(medProps),
    rescue: arrayOf(medProps),
  }),
};

export default PatientPlan;
