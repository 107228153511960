import React from 'react';
import { useTranslation } from 'react-i18next';

import TabSection from '../TabSection';
import RescueUsageChart from '../RescueUsageChart';
import ControllerAdherenceChart from '../ControllerAdherenceChart';

const MedicationUsage = ({ medications, disease, charts, numberDays, timeZone }) => {
  const { t } = useTranslation();

  return (
    <TabSection tabText={t('MEDICATION_USAGE')} numberDays={numberDays}>
      {medications.rescue && medications.rescue.length > 0 && (
        <RescueUsageChart
          medications={medications.rescue}
          disease={disease}
          src={charts.rescueUsage}
          timeZone={timeZone}
        />
      )}
      {medications.controller && medications.controller.length > 0 && (
        <ControllerAdherenceChart
          medications={medications.controller}
          src={charts.controllerAdherence}
          timeZone={timeZone}
        />
      )}
    </TabSection>
  );
};

export default MedicationUsage;
