import moment from 'moment-timezone';

export const displayDate = (date, locale, fmt = 'L') =>
  moment(date)
    .locale(locale)
    .format(fmt);

export const displayDateRange = (range, locale, format = 'll') =>
  range
    .slice(0, 2)
    .map(d => displayDate(d, locale, format))
    .join(' - ');

export const timeFrame = ({ dailySummary, endDate, reportLength = 30 }) => {
  const _endDate =
    'undefined' === typeof endDate ? dailySummary.trends.endDate : endDate;
  const reportStart = moment.utc(_endDate).subtract(reportLength - 1, 'days');

  return [
    reportStart.format('YYYY-MM-DD'),
    moment.utc(_endDate).format('YYYY-MM-DD')
  ];
};

export const weekToDaysReducer = (arr, d) => arr.concat(incrementToDays(d, 8));

const incrementToDays = (datum, numDays) => {
  const ary = new Array(numDays);

  for (let i = 0; i < numDays; i++) {
    ary[i] = Object.assign({}, datum, {
      date: parseDate(
        moment(datum.date)
          .add(i, 'days')
          .toDate()
      )
    });
  }

  return ary;
};

export const parseDate = d => {
  return new Date(
    ...moment
      .parseZone(d)
      .toArray()
      .slice(0, 3)
  );
};

export const tzDate = (date, tz) => moment(date).tz(tz);
