import React from 'react';
import { I18nextProvider } from 'react-i18next';

import LoggerContext from '../../context/logger';
import LocaleContext from '../../context/locale';
import Contents from './Contents';
import ErrorBoundary from '../../components/ErrorBoundary';
import '../../fonts.js';

// I'd prefer having the provider further out where we actually do the rendering,
// but for whatever reasons, things broke when I tried to use it outside of,
// or immediately within, the PDFViewer in the demo. So this because a common location
// that solved local and node cases.
const PatientSummary = ({ data, i18n, logger, ...rest }) => (
  <LoggerContext.Provider value={logger}>
    <LocaleContext.Provider value={data.locale}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <Contents data={data} {...rest} />
        </ErrorBoundary>
      </I18nextProvider>
    </LocaleContext.Provider>
  </LoggerContext.Provider>
);

export default PatientSummary;
