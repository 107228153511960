import React, { useContext } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import Bold from '../Bold';
import GreyText from '../GreyText';

import { lastSyncDate } from '../../utilities/medications';
import { displayDate } from '../../utilities/dates';
import LocaleContext from '../../context/locale';

const ownStyles = StyleSheet.create({
  text: {
    fontSize: 10,
  },
});

const MedicationTitle = ({
  style = {},
  medication,
  timeZone,
  ...restProps
}) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  const lastSync = lastSyncDate(medication.sensors, timeZone, true);

  const sensorText =
    !medication.sensors || medication.sensors.length === 0
      ? 'NO_SENSORS_ATTACHED'
      : !lastSync
        ? 'NO_SYNC'
        : 'LAST_SYNC_DATE';

  return (
    <Bold style={{ ...ownStyles.text, ...style }} {...restProps}>
      {medication.medication.name}{' '}
      <GreyText>
        ({t(sensorText, { date: displayDate(lastSync, locale, 'll') })})
      </GreyText>
    </Bold>
  );
};

export default MedicationTitle;
