import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';

import StatusSectionTitle from '../StatusSectionTitle';

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
  },
  imgContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingBottom: 5,
  },
});

const MetricChart = ({
  component: ImageComponent = Image,
  style,
  header,
  src,
  dimensions,
}) => (
  <View style={{ ...styles.container, ...style }}>
    <StatusSectionTitle>{header}</StatusSectionTitle>
    <View style={styles.imgContainer}>
      <ImageComponent style={dimensions} src={src} />
    </View>
  </View>
);

export default MetricChart;
