import React from 'react';
import { shape, node } from 'prop-types';
import { StyleSheet } from '@react-pdf/renderer';

import Bold from '../Bold';

const ownStyles = StyleSheet.create({
  fontSize: 10,
  color: '#333',
  textTransform: 'uppercase',
  paddingBottom: 5,
});

const GraphTitle = ({ children, style = {}, ...restProps }) => (
  <Bold style={{ ...ownStyles, ...style }} {...restProps}>
    {children}
  </Bold>
);

GraphTitle.propTypes = {
  style: shape({}),
  children: node.isRequired,
};

GraphTitle.defaultProps = {
  style: {},
};

export default GraphTitle;
