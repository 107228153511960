import { sortBy } from './arrays';
import { tzDate } from './dates';
import LoggerService from '../services/logger';
const { logger } = LoggerService;

const dateFromSensors = (sensors, reducer) => {
  if (!sensors || sensors.length === 0) {
    return [];
  }

  return sensors.reduce(reducer, []).sort();
};

export const lastSyncDate = (sensors, timeZone, newest) => {
  const dates = dateFromSensors(sensors, (ary, sensor) =>
    ary.concat(
      sensor.lastTransmitDevice && sensor.lastTransmitDevice.date
        ? sensor.lastTransmitDevice.date
        : []
    )
  );

  const idx = newest ? dates.length - 1 : 0;

  return dates[idx] ? tzDate(dates[idx], timeZone) : null;
};

export const firstSyncDate = (sensors, timeZone) => {
  const dates = dateFromSensors(sensors, (ary, sensor) =>
    ary.concat(sensor.firstSyncDate || [])
  );

  return dates[0] ? tzDate(dates[0], timeZone) : null;
};

export const adherenceSummary = (medications, days) => {
  return medications
    .filter(m => m.medication.type === 'controller')
    .map(med => {
      const scores = days
        .map(day => {
          const values = day.controller
            ? day.controller.meds.find(m => m.mid === med.medicationId) || {}
            : {};

          return values.percent;
        })
        .filter(num => typeof num === 'number');

      return {
        name: med.medication.shortName,
        adherence: Math.round(
          scores.reduce((tot, num) => tot + num, 0) / scores.length
        )
      };
    })
    .sort(sortBy('name'));
};

export const medsByType = medications =>
  medications.reduce(
    (accumulator, med) => {
      const type = med.medication.type;

      if (!accumulator[type]) {
        logger.warn &&
          logger.warn({ msg: `Unexpected medicatation type: ${type}` });
        return accumulator;
      }

      accumulator[type] = accumulator[type].concat(med);
      return accumulator;
    },
    { rescue: [], controller: [] }
  );
