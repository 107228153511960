import React, { useContext } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import RoundedBox from '../RoundedBox';
import Bold from '../Bold';
import AsthmaStatus from '../AsthmaStatus';
import COPDStatus from '../COPDStatus';
import StatusSectionTitle from '../StatusSectionTitle';
import TabSection from '../TabSection';
import QuizScore from '../QuizScore';
import MetricScore from '../MetricScore';
import MetricChart from '../MetricChart';
import GreyText from '../GreyText';
import KV from '../KV';

import LocaleContext from '../../context/locale';

import colors from '../../utilities/colors';
import { displayDate } from '../../utilities/dates';

const MARGIN = 2;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
    width: '50%',
  },
  rightPadding: {
    paddingRight: MARGIN,
  },
  leftPadding: {
    paddingLeft: MARGIN,
  },
  wrapRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  text: {
    fontSize: 10,
  },
  adherenceColumn: {
    width: 'auto',
    marginRight: MARGIN * 2,
  },
  alignRight: {
    textAlign: 'right',
  },
  flexGrow: {
    flexGrow: 1,
  },
});

const leftColumn = { ...styles.column, ...styles.rightPadding };
const rightColumn = { ...styles.column, ...styles.leftPadding };

const HealthStatus = ({
  charts,
  disease,
  quiz,
  days = [],
  controlStatus = {},
  adherence,
  sensorCount = 0,
  lastSync,
}) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  const numberDays = days.length;
  const rescueNights = days.filter((d) => d.rescue.nightEvents > 0).length;
  const DiseaseStatus = disease === 'asthma' ? AsthmaStatus : COPDStatus;

  return (
    <TabSection tabText={t('HEALTH_STATUS')} numberDays={numberDays}>
      <View style={styles.row}>
        <DiseaseStatus
          controlStatus={controlStatus}
          days={days}
          rescueNights={rescueNights}
          style={leftColumn}
        />
        <View style={rightColumn}>
          <View style={{ ...styles.row, ...styles.flexGrow }}>
            <MetricChart
              style={leftColumn}
              header={t('RESCUE_TIME_OF_DAY')}
              src={charts.timeOfDay}
              dimensions={{ width: 130, height: 104 }}
            />
            <MetricChart
              style={rightColumn}
              header={t('RESCUE_DAY_OF_WEEK')}
              src={charts.dayOfWeek}
              dimensions={{ width: 130, height: 112 }}
            />
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={leftColumn}>
          <RoundedBox style={styles.flexGrow} color={colors.blue}>
            <StatusSectionTitle>
              {t('CONTROLLER_ADHERENCE')}
              <GreyText>
                {' '}
                {t('LAST_NUM_DAYS', { number: numberDays }).toLowerCase()}
              </GreyText>
            </StatusSectionTitle>
            <View style={styles.wrapRow}>
              {adherence.length > 0 ? (
                adherence.map((med, i) => (
                  <View
                    style={{
                      ...styles.column,
                      ...styles.adherenceColumn,
                    }}
                    key={`adherence-${i}`}
                  >
                    <Bold style={styles.text}>{med.name}</Bold>
                    <MetricScore>
                      {isNaN(med.adherence) ? t('N_A') : `${med.adherence}%`}
                    </MetricScore>
                  </View>
                ))
              ) : (
                <MetricScore>{t('N_A')}</MetricScore>
              )}
            </View>
          </RoundedBox>
        </View>
        <View style={rightColumn}>
          <RoundedBox style={styles.flexGrow} color={colors.grey}>
            <QuizScore disease={disease} quiz={quiz} />
          </RoundedBox>
        </View>
      </View>
      <View style={styles.alignRight}>
        <KV
          style={{ fontSize: 8 }}
          keyText={t('LAST_SENSOR_SYNC', {
            count: sensorCount,
          })}
          valueText={lastSync ? displayDate(lastSync, locale, 'll') : t('N_A')}
        />
      </View>
    </TabSection>
  );
};

export default HealthStatus;
