module.exports = {
  blue: '#20C3F3',
  purple: '#6b1671',
  green: '#40AD00',
  deepRed: '#D70000',
  red: '#EE0037',
  yellow: 'yellow',
  orange: '#F2A14C',
  grey: '#8C8E8F',
  darkGrey: '#333',
  lightGrey: '#C0C0C0',
  lightestGrey: '#E0E0E0',
  brown: '#8F4E00',
  text: '#555',
  white: '#FFFFFF',
  mauve: '#C7A6CD',
  teal: '#7BE0C3',
  lightBlue: '#EBFBFE'
};
