import React from 'react';
import { node } from 'prop-types';
import { StyleSheet, View } from '@react-pdf/renderer';

import Bold from '../Bold';
import GreyText from '../GreyText';

const styles = StyleSheet.create({
  container: {
    marginBottom: 5,
  },
  text: { fontSize: 10 },
});

const StatusSectionTitle = ({ subText, children }) => (
  <View style={styles.container}>
    <Bold style={styles.text}>{children}</Bold>
    {subText && (
      <Bold>
        <GreyText style={styles.text}>{subText}</GreyText>
      </Bold>
    )}
  </View>
);

StatusSectionTitle.propTypes = {
  children: node.isRequired,
  subText: node
};

export default StatusSectionTitle;
