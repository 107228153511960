import React from 'react';
import { node, shape } from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  fontStyle: 'oblique',
});

// restProps is intended to encapuslate arbitrary react-pdf props
// tied to things like orphan and widow protection
// https://react-pdf.org/advanced#orphan-&-widow-protection
const Bold = ({ style, children, ...restProps }) => (
  <Text style={{ ...styles, ...style }} {...restProps}>
    {children}
  </Text>
);

Bold.propTypes = {
  children: node.isRequired,
  style: shape({}),
};

Bold.defaultProps = {
  style: {},
};

export default Bold;
