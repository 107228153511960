// TODO: all values are 0?
const moment = require('moment');

const {
  max,
  select: d3Select,
  scaleLinear,
  scaleBand,
  axisBottom,
  axisLeft,
  format: d3Format
} = require('d3');

const Chart = require('../chartBase');

const colors = require('../../utilities/colors');

module.exports = class DayOfWeek extends Chart {
  type = 'dayOfWeek';

  width = 250;

  height = 200;

  margins = { top: 15, right: 5, bottom: 25, left: 30 };

  yFormat = '.0%';

  color = colors.orange;

  buildData(data) {
    const { locale } = this;
    const trend = data.dailySummary.trends.dayofweek || {};

    const ORDER = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const localeData = moment()
      .locale(locale)
      .localeData();

    const initialOrder = localeData.weekdaysMin().map(s => s.toLocaleUpperCase(locale));
    const localeOrder = localeData.weekdaysMin(true).map(s => s.toLocaleUpperCase(locale));

    return ORDER.map((key, i) => ({
      value: trend[key] / 100,
      key,
      label: initialOrder[i]
    })).sort((day1, day2) =>
      localeOrder.indexOf(day1.label) > localeOrder.indexOf(day2.label) ? 1 : -1
    );
  }

  renderSVG(node) {
    const { margins, width, height, data, color, yFormat } = this;

    const chartHeight = height - margins.top - margins.bottom;
    const chartWidth = width - margins.left - margins.right;
    const maxY = max(data, d => d.value);
    const formatter = d3Format(yFormat);

    const xScale = scaleBand()
      .domain(data.map(d => d.label))
      .range([0, chartWidth])
      .padding(0.3);

    const yScale = scaleLinear()
      .domain([0, maxY || 1])
      .range([chartHeight, 0]);

    const xAxis = axisBottom(xScale)
      .tickSize(0)
      .tickPadding(10);

    const yAxis = axisLeft(yScale)
      .ticks(5)
      .tickFormat(formatter)
      .tickSizeInner(-chartWidth)
      .tickSizeOuter(0)
      .tickPadding(5);

    const svg = d3Select(node)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
      .attr('font-family', `"${this.fontFamily}", sans-serif`);

    const yGuide = svg
      .append('g')
      .attr('class', 'axis axis-y')
      .attr('transform', `translate(${margins.left},${margins.top})`)
      .attr('shape-rendering', 'crispEdges')
      .call(yAxis);

    yGuide.selectAll('text').attr('dy', -2);

    yGuide
      .select('.domain')
      .attr('fill', 'none')
      .attr('stroke', 'none');

    const xGuide = svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr(
        'transform',
        `translate(${margins.left},${chartHeight + margins.top})`
      )
      .attr('shape-rendering', 'crispEdges')
      .call(xAxis);

    xGuide
      .select('.domain')
      .attr('fill', 'none')
      .attr('stroke', 'none');

    svg
      .selectAll('text')
      .attr('shape-rendering', 'crispEdges')
      .attr('fill', colors.text)
      .attr('stroke', 'none');

    svg
      .selectAll('line')
      .attr('stroke', colors.lightestGrey)
      .attr('fill', colors.lightestGrey);

    svg
      .selectAll('bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('transform', `translate(${margins.left},${margins.top})`)
      .attr('class', 'bar')
      .style('fill', d => d.color || color)
      .attr('x', d => xScale(d.label))
      .attr('width', xScale.bandwidth())
      .attr('y', d => yScale(d.value))
      .attr('height', d => chartHeight - yScale(d.value));

    return node;
  }
};
