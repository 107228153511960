import React from 'react';
import ReactJson from 'react-json-view';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loading from '../Loading';

const ChartRenderer = ({ json, blobUrl, loading }) => (
  <Row>
    {loading && <Loading />}
    <Col
      style={{
        boxSizing: 'border-box',
        padding: '1rem',
      }}
    >
      <ReactJson src={json || {}} />
    </Col>
    <Col
      style={{
        boxSizing: 'border-box',
        padding: '1rem',
      }}
    >
      <iframe
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="mypdf"
        src={blobUrl}
      />
    </Col>
  </Row>
);

export default ChartRenderer;
