import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import React from 'react';
import { render } from 'react-dom';

import Main from './Main';
import LoggerService from '../../src/services/logger';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

LoggerService.initialize(window.console);

render(
  <I18nextProvider i18n={i18n}>
    <Main />
  </I18nextProvider>,
  document.querySelector('#demo')
);
