import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: 'rgba(255,255,255,0.7)'
    }}
  >
    <Spinner animation="grow" variant="info" />
    loading...
  </div>
);

export default Loading;
