let path;

if (!process.browser) {
  path = require('path');
}

// path.resolve forms an absolute path for the filesystem,
// which will vary between machines and result in test failures
// when, e.g, the file library is not at /Users/marcbrakken/Dev/propeller/ph-report-pdf
// however, we want to use path.resolve in the real world
// so the formed paths can be used elsewhere in the code
function buildPath(...parts) {
  if (global.__USE_LOCAL_PATHS__) {
    return path.join(...parts);
  } else {
    return path.resolve(__dirname, ...parts);
  }
}

export function getDirectoryPath(directory) {
  return path
    ? buildPath('..', directory)
    : directory;
}

export function getFilePath(directory) {
  const directoryPath = getDirectoryPath(directory);

  return function (file) {
    return path
      ? path.join(directoryPath, file)
      : `${directoryPath}/${file}`;
  };
}
