const {
  scaleLinear,
  scaleTime,
  axisLeft,
  axisBottom,
  area,
  curveBasis,
  bisector,
  format: d3Format,
  timeMonday
} = require('d3');
const moment = require('moment');

const TimeSeries = require('../timeSeries');

const {
  timeFrame,
  weekToDaysReducer,
  parseDate,
  tzDate
} = require('../../utilities/dates');
const { sortByDate } = require('../../utilities/arrays');
const { lastSyncDate, firstSyncDate } = require('../../utilities/medications');
const LoggerService = require('../../services/logger');
const { logger } = LoggerService;

const timeBisector = bisector(d => d.date);

const isNumber = n => typeof n === 'number';

// const findLastEventDate = (days, timeZone) => {
//   for (let index = days.length - 1; index >= 0; index--) {
//     const day = days[index];
//     if (isNumber(day.dayPercent)) {
//       const nextDay = days[index + 1];
//       const date = nextDay ? nextDay.date : day.date;
//       return tzDate(date, timeZone);
//     }
//   }
// };

const findFirstEventDate = (days, timeZone) => {
  const firstEvent = days.find(day => isNumber(day.dayPercent));

  return firstEvent && tzDate(firstEvent.date, timeZone);
};

// because weekly adherence result is as of the last time we heard
// from the user, eg. last sync or last manual event, we want to
// cut off data after the last-heard-from date so that we eg. don't
// graph a full week at 100% if we only know about 1 day.
//
// Similarly, if data for a week starts in the middle of that week,
// we don't want to cast that data further into the past.
const graphableControllerData = (data, { lastDate, firstDate }) => {
  // return data;
  // bisector.right returns the index _after_ the matching index.
  // we back up by 1 to include the matching index
  const idx1 = firstDate ? timeBisector.right(data, firstDate) - 1 : 0;
  // and we make sure we don't have a negative result
  const firstIdx = idx1 > -1 ? idx1 : 0;

  const lastIdx = lastDate
    ? timeBisector.left(data, lastDate)
    : data.length - 1;

  const dataRange = data.slice(firstIdx, lastIdx);

  return lastDate
    ? dataRange.concat({
      ...data[lastIdx],
      date: moment(lastDate)
    })
    : dataRange;
};

const isDefined = d => isNumber(d.percent);

const calculateTotalUses = (data, [start, end]) => {
  const idx1 = timeBisector.left(data, start);
  const idx2 = timeBisector.left(data, end);

  return data
    .slice(idx1, idx2)
    .reduce((total, day) => total + (day.percent || 0), 0);
};

module.exports = class ControllerChart extends TimeSeries {
  buildData(data) {
    const weeklyAdherence = data.controllerAdherence.sort(sortByDate);
    const days = data.dailySummary.days.map(day => {
      day.date = parseDate(day.date);
      return day;
    });

    const dateRange = timeFrame(data).map(parseDate);

    const medications = data.medications
      .filter(m => m.medication.type === 'controller')
      .map(med => {
        // let's avoid mutation
        const adherence = weeklyAdherence
          .map(week => {
            const values =
              week.medications &&
              week.medications.find(m => med.medicationId === m.mid);

            return {
              date: parseDate(week.date),
              percent: values ? values.percent : undefined
            };
          })
          .sort(sortByDate)
          .reduce(weekToDaysReducer, [])
          .map(day => {
            const summary = days.find(
              d => d.date.toDateString() === day.date.toDateString()
            );

            if (summary && summary.controller && summary.controller.meds) {
              const medDaily = summary.controller.meds.find(
                m => m.mid === med.medicationId
              );
              // we won't have medDaily if the med was not on the plan for this day
              // medDaily.percent will be a number if we had some kind of signal
              // for this med on the day (sync, manual event)
              // medDaily.percent will be null if we expect a signal but didn't receive one yet
              if (medDaily) {
                day.dayPercent = medDaily.percent;
              }
            }

            return day;
          });

        const total = calculateTotalUses(adherence, dateRange);

        return {
          ...med,
          adherence,
          total
        };
      });

    return {
      medications,
      dateRange
    };
  }

  // we are looping over each medication to render graphs for each.
  // in theory, we could optimize this by taking some calculations
  // (xaxis, dimensions, etc) out of the loop.
  renderSVG(node, medication) {
    const { data, margins, width, height, timeZone, d3Locale, locale } = this;
    const {
      dateRange: [start, end]
    } = data;

    const chartWidth = width - margins.left - margins.right;
    const chartHeight = height - margins.top - margins.bottom;

    const xScale = scaleTime()
      .domain([
        start,
        moment(end)
          .add(23, 'hours')
          .toDate()
      ])
      .range([0, chartWidth]);

    const yScale = scaleLinear()
      .domain([0, 100])
      .range([chartHeight, 0]);

    const areaGraph = area()
      .x(d => xScale(d.date))
      .y0(chartHeight)
      .y1(d => yScale(d.percent))
      .curve(curveBasis)
      .defined(isDefined);

    const formatWeek = d3Locale.format('%b %-d');

    const svg = this.buildFrame(node, {
      xScale,
      xAxis: axisBottom()
        .ticks(timeMonday)
        .tickFormat(d => formatWeek(d).toLocaleUpperCase(locale))
        .tickSize(0)
        .tickPadding(10)
        .scale(xScale),
      yAxis: axisLeft()
        .scale(yScale)
        .tickSizeInner(-chartWidth)
        .tickSizeOuter(0)
        .ticks(5)
        .tickFormat(p => d3Format('.0%')(p / 100))
    });

    const container = svg
      .append('g')
      .attr('class', 'graph-container')
      .attr('transform', `translate(${margins.left}, ${margins.top})`)
      .attr('clip-path', 'url(#chart-mask)');

    const firstSync = firstSyncDate(medication.sensors, timeZone);
    const lastSync = lastSyncDate(medication.sensors, timeZone, true);

    const firstEventDate = findFirstEventDate(medication.adherence, timeZone);
    // const lastEventDate = findLastEventDate(medication.adherence, timeZone);

    // in theory there may be sensor events before the first sync
    // although that's unlikely in the real world...

    // worth pointing out that firstEventDate will always start at the beginning of the day,
    // not at whatever time the actual event occurred
    const firstDate = moment(firstSync).isBefore(firstEventDate)
      ? firstSync
      : firstEventDate;

    const toGraph = graphableControllerData(medication.adherence, {
      lastDate: lastSync,
      firstDate
    });

    container
      .append('path')
      .datum(toGraph)
      .attr('fill', '#20C3F3')
      .attr('fill-opacity', '0.8')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'butt')
      .attr('d', areaGraph);

    const boundsProps = {
      xScale,
      firstDate,
      lastSync,
      totalUsages: medication.total
    };

    this.appendBounds(container, boundsProps);
    this.appendOverlay(container, boundsProps);

    return node;
  }

  /**
   * override the default render method because we want
   * to dispatch a list of charts to render, for each med
   */
  render(window) {
    const self = this;
    const { medications = [] } = this.data;

    return Promise.all(
      medications.map(med => {
        return new Promise(function(resolve, reject) {
          const svg = self.renderSVG(window.document.createElement('svg'), med);

          if (svg) {
            return resolve(svg);
          } else {
            return reject(
              new Error(
                `Failed to create adherence chart for ${med.medicationId}`
              )
            );
          }
        })
          .then(svg => self.svgToPNG(svg.outerHTML))
          .catch(err => {
            logger.error && logger.error(err);
            return null;
          });
      })
    ).then(charts =>
      medications.reduce((coll, med, i) => {
        coll[med.medicationId] = charts[i];
        return coll;
      }, {})
    );
  }
};
