import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import TabHeader from '../TabHeader';
import { node, number, string } from 'prop-types';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
});

const TabSection = ({ numberDays, children, tabText }) => (
  <View style={styles.container}>
    <TabHeader numberDays={numberDays}>{tabText}</TabHeader>
    {children}
  </View>
);

TabSection.propTypes = {
  children: node.isRequired,
  numberDays: number,
  tabText: string.isRequired,
};

TabSection.defaultProps = {
  numberDays: 30,
};

export default TabSection;
