import React from 'react';
import LoggerContext from '../../context/logger';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    const logger = this.context || {};

    if (!error) {
      error = new Error('Unknown error');
    } else if (typeof error !== 'object') {
      error = new Error(error);
    }

    logger.error && logger.error(error, { info });
    throw error;
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.contextType = LoggerContext;

export default ErrorBoundary;
