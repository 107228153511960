import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import RoundedBox from '../RoundedBox';
import StatusSectionTitle from '../StatusSectionTitle';
import KV from '../KV';
import NightRescueUse from '../NightRescueUse';

import colors from '../../utilities/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  text: {
    fontSize: 10,
  },
});

const asPercent = (num, den) => Math.round((100 * num) / den);

const AsthmaStatus = ({ controlStatus, days, rescueNights, style }) => {
  const { t } = useTranslation();

  const knownDays = ['good', 'fair', 'poor'].reduce(
    (total, status) => total + controlStatus[status],
    0
  );
  const totalDays = days.length;
  const unknownDays = totalDays - knownDays;

  return (
    <View style={{ ...styles.container, ...style }}>
      <RoundedBox color={colors.deepRed}>
        <StatusSectionTitle>{t('ASTHMA_CONTROL_STATUS')}</StatusSectionTitle>
        <KV
          keyText={`${t('WELL_CONTROLLED')}:`}
          valueText={`${asPercent(controlStatus.good, totalDays)}%`}
        />
        <KV
          keyText={`${t('NOT_WELL_CONTROLLED')}:`}
          valueText={`${asPercent(controlStatus.fair, totalDays)}%`}
        />
        <KV
          keyText={`${t('VERY_POORLY_CONTROLLED')}:`}
          valueText={`${asPercent(controlStatus.poor, totalDays)}%`}
        />
        {unknownDays > 0 && (
          <KV
            keyText={`${t('UNKNOWN')}:`}
            valueText={`${asPercent(unknownDays, totalDays)}%`}
          />
        )}
      </RoundedBox>
      <NightRescueUse count={rescueNights} numberDays={totalDays} />
    </View>
  );
};

export default AsthmaStatus;
