const {
  bisector,
  max,
  scaleLinear,
  scaleTime,
  axisLeft,
  axisBottom,
  line,
  curveBasis,
  stack: d3Stack
} = require('d3');
const moment = require('moment');

const TimeSeries = require('../timeSeries');

const colors = require('../../utilities/colors');
const { parseDate, timeFrame, tzDate } = require('../../utilities/dates');
const { sortByDate } = require('../../utilities/arrays');

const BAR_PADDING = 10;
const INCLUDE_PREEMPTIVE = false;

const leftBisector = bisector(d => d.date).left;

const preemptiveUses = day =>
  INCLUDE_PREEMPTIVE ? day.preemptiveUsages || 0 : 0;

const getTotalEvents = (days, [start, end]) => {
  const idx1 = leftBisector(days, start);
  const idx2 = leftBisector(days, end) + 1;

  return days
    .slice(idx1, idx2)
    .reduce((total, day) => total + day.totalEvents + preemptiveUses(day), 0);
};

const findMaxY = days => {
  const dataMax = max(
    days,
    day => (day.totalEvents || 0) + preemptiveUses(day)
  );

  return Math.max(dataMax, 4) + 1;
};

module.exports = class RescueChart extends TimeSeries {
  type = 'rescueChart';

  buildData(data) {
    const days = data.dailySummary.days.map(day => ({
      date: parseDate(day.date),
      ...day.rescue
    })).sort(sortByDate);
    const lastSync = data.patient.sync && data.patient.sync.lastRescue;
    const firstDate = data.patient.sync && data.patient.sync.firstRescue;
    const dateRange = timeFrame(data).map(parseDate);

    return {
      dateRange,
      disease: data.patient.disease,
      days,
      firstDate: firstDate && tzDate(firstDate, data.patient.timeZone),
      lastSync: lastSync && tzDate(lastSync, data.patient.timeZone),
      total: getTotalEvents(days, dateRange)
    };
  }

  renderSVG(node) {
    const { width, height, margins, data, d3Locale } = this;
    const { dateRange, days, disease } = data;
    const [start, end] = dateRange;

    const CHART_WIDTH = width - margins.left - margins.right;
    const CHART_HEIGHT = height - margins.top - margins.bottom;
    const nDays = moment(end).diff(start, 'days');
    const dayWidth = CHART_WIDTH / nDays;
    const barWidth = dayWidth - BAR_PADDING;
    const maxY = findMaxY(days);

    // our bounding dates were right at the edge of the chart,
    // pulling them in so they're not cut off at the extents
    const offset = dayWidth / 2;

    const xScale = scaleTime()
      .domain(dateRange)
      .range([0 + offset, CHART_WIDTH - offset]);

    const yScale = scaleLinear()
      .domain([0, maxY])
      .range([CHART_HEIGHT, 0]);

    const keys = INCLUDE_PREEMPTIVE
      ? ['totalEvents', 'preemptiveUsages']
      : ['totalEvents'];

    const stack = d3Stack().keys(keys);

    const stackColors = [colors.orange, colors.teal];

    const formatDay = d3Locale.format('%-d');

    // we use fullScale to draw dark lines for the top/bottom of the graph...
    const svg = this.buildFrame(node, {
      xScale,
      xAxis: axisBottom()
        .tickFormat(formatDay)
        .tickSize(0)
        .tickPadding(10)
        .scale(xScale),
      yAxis: axisLeft()
        .scale(yScale)
        .tickSizeInner(-CHART_WIDTH)
        .tickSizeOuter(0)
        .ticks(5)
        .tickFormat(d => (d % 2 === 0 ? d : undefined)),
      includeMonths: true
    });

    const container = svg
      .append('g')
      .attr('class', 'graph-container')
      .attr('transform', `translate(${margins.left}, ${margins.top})`)
      .attr('clip-path', 'url(#chart-mask)');

    const barOffset = barWidth / 2;

    container
      .selectAll('g.bar-stack')
      .data(stack(days))
      .enter()
      .append('g')
      .style('fill', (d, i) => stackColors[i])
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.data.date) - barOffset)
      .attr('y', d => yScale(d[1]))
      .attr('width', barWidth)
      .attr('height', d => yScale(d[0]) - yScale(d[1]));

    container
      .selectAll('.night-rescue-bar')
      .data(days)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.date) - barOffset)
      .attr('y', d => yScale(d.nightEvents))
      .attr('width', barWidth)
      .attr('height', d => CHART_HEIGHT - yScale(d.nightEvents))
      .attr('fill', colors.brown);

    if (disease === 'copd') {
      const baseLine = line()
        .x(d => xScale(d.date))
        .y(d => yScale(d.baseline))
        .curve(curveBasis)
        .defined(d => typeof d.baseline === 'number');

      container
        .append('path')
        .datum(days)
        .attr('d', baseLine)
        .attr('fill', 'none')
        .attr('stroke', colors.green)
        .attr('stroke-width', 3)
        .attr('vector-effect', 'non-scaling-stroke');
    }

    const boundsProps = {
      xScale,
      firstDate: data.firstDate,
      lastSync: data.lastSync,
      totalUsages: data.total
    };

    this.appendBounds(container, boundsProps);
    this.appendOverlay(container, boundsProps);

    return node;
  }
};
