import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';

import Normal from '../Normal';
import Bold from '../Bold';

const ownStyles = StyleSheet.create({
  fontSize: 10
});

const KV = ({ keyText, valueText, style }) => (
  <Normal style={{ ...ownStyles, ...style }}>
    {keyText} <Bold>{valueText}</Bold>
  </Normal>
);

export default KV;
