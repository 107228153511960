import React from 'react';
import { node, string } from 'prop-types';
import { StyleSheet } from '@react-pdf/renderer';

import Bold from '../Bold';
import Normal from '../Normal';

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    marginRight: 8,
  },
});

const LegendItem = ({ mark, color, children }) => (
  <Bold style={styles.text}>
    <Normal style={{ color, fontFamily: 'CircularPro' }}>{mark}</Normal>{' '}
    {children}
  </Bold>
);

LegendItem.propTypes = {
  mark: string,
  color: string,
  children: node.isRequired,
};

LegendItem.defaultProps = {
  mark: '●',
};

export default LegendItem;
