import React from 'react';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { supportedLanguages } from '../../../src/services/locales';

const reports = [
  { file: 'copd_a', title: 'COPD A' },
  { file: 'asthma_a', title: 'Asthma A' },
  { file: 'asthma_b', title: 'Asthma B' },
  { file: 'asthma_c', title: 'Asthma C' },
  { file: 'data', title: 'Little Ben' },
  { file: 'de-CH-test', title: 'de-CH-test' },
  { file: 'de-CH2', title: 'de-CH2' },
  { file: 'null1', title: 'null data' },
  { file: 'patient-data', title: 'Xao Confirmed2' },
  { file: 'TEST_DATA_COPD', title: 'TEST_DATA_COPD' },
  { file: 'TEST_DATA', title: 'TEST_DATA' },
  { file: 'Enerzair_Ventolin', title: 'Traveling Charles' },
];

const ReportList = ({ currentReport, onChange }) => (
  <Nav>
    <Form.Group className="report-list">
      {reports.map((report) => (
        <Form.Check
          key={`report-${report.file}`}
          type="radio"
          id={`report-${report.file}`}
          className="d-block report-option"
        >
          <Form.Check.Input
            type="radio"
            value={report.file}
            checked={report.file === currentReport}
            onChange={onChange}
          />
          <Form.Check.Label>{report.title}</Form.Check.Label>
        </Form.Check>
      ))}
    </Form.Group>
  </Nav>
);

const SideBar = ({
  language,
  loading,
  currentReport,
  localeOnChange,
  reportOnChange,
  renderReport,
}) => (
  <Col as="nav" md={2} className="sidebar">
    <div className="sticky">
      <Form.Group controlId="reportLocale">
        <Form.Label>Choose Locale:</Form.Label>
        <Form.Control as="select" value={language} onChange={localeOnChange}>
          {supportedLanguages.map((locale) => (
            <option key={locale} value={locale}>
              {locale}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <ReportList currentReport={currentReport} onChange={reportOnChange} />

      <Col className="button-wrapper">
        <Button block onClick={renderReport} disabled={loading}>
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </>
          ) : (
            'Render Report'
          )}
        </Button>
      </Col>
    </div>
  </Col>
);

export default SideBar;
