import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { node, shape } from 'prop-types';

const ownStyle = StyleSheet.create({
  fontWeight: 'normal',
});

const Normal = ({ style, children }) => (
  <Text style={{ ...ownStyle, ...style }}>{children}</Text>
);

Normal.propTypes = {
  children: node.isRequired,
  style: shape({}),
};

Normal.defaultProps = {
  style: {},
};

export default Normal;
