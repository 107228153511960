import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import GraphTitle from '../GraphTitle';
import MedicationTitle from '../MedicationTitle';
import LegendItem from '../LegendItem';

import { sortBy } from '../../utilities/arrays';
import colors from '../../utilities/colors';

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
  },
  imgContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingBottom: 5,
  },
  legendContainer: {
    flexDirection: 'row',
    paddingLeft: 20,
    marginTop: 5,
  },
  titleOffset: {
    paddingLeft: 20,
  },
});

const RescueUsageChart = ({ medications, disease, timeZone, src }) => {
  const { t } = useTranslation();

  return (
    <View wrap={false}>
      <GraphTitle style={styles.titleOffset}>
        {t('RESCUE_MEDICATION_USAGE')}
      </GraphTitle>
      {medications.sort(sortBy('name')).map((m) => (
        <MedicationTitle
          key={m.medicationId}
          medication={m}
          timeZone={timeZone}
          style={styles.titleOffset}
        />
      ))}
      <View style={styles.legendContainer}>
        <LegendItem color={colors.orange}>{t('TOTAL_EVENTS')}</LegendItem>
        <LegendItem color={colors.brown}>
          {t('NIGHTTIME_USAGE_LEGEND')}
        </LegendItem>
        {/* <LegendItem color={colors.teal}>{t('PREEMPTIVE_USAGES')}</LegendItem> */}
        {disease === 'copd' && (
          <LegendItem color={colors.green} mark="—">
            {t('RESCUE_BASELINE')}
          </LegendItem>
        )}
      </View>
      <View style={styles.imgContainer}>
        <Image style={{ width: 580, height: 150 }} src={src} />
      </View>
    </View>
  );
};

export default RescueUsageChart;
