import React from 'react';
import { node, shape, string } from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

import colors from '../../utilities/colors';

const ownStyles = StyleSheet.create({
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 8,
  padding: '5 10',
  marginBottom: 5,
});

const RoundedBox = ({ color, style, children }) => (
  <View
    style={{
      ...ownStyles,
      borderColor: color,
      ...style,
    }}
  >
    {children}
  </View>
);

RoundedBox.propTypes = {
  children: node.isRequired,
  color: string,
  style: shape({}),
};

RoundedBox.defaultProps = {
  color: colors.lightGrey,
  style: {},
};

export default RoundedBox;
