import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import GraphTitle from '../GraphTitle';
import MedicationTitle from '../MedicationTitle';

import { sortBy } from '../../utilities/arrays';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  imgContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingBottom: 5,
  },
  titleOffset: {
    paddingLeft: 20,
  },
  medPadding: {
    paddingBottom: 3,
  },
});

const medPadding = { ...styles.titleOffset, ...styles.medPadding };

// minPresenceAhead is used to make sure our title isn't on a separate page than the first graph
// however that doesn't work if we also want the title to appear on every page
// with a chart (eg. with fixed={true}), so let's settle for not orphaning
// https://react-pdf.org/advanced#orphan-&-widow-protection
const ControllerAdherenceChart = ({ medications, src, timeZone }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <GraphTitle minPresenceAhead={300} style={styles.titleOffset}>
        {t('CONTROLLER_MEDICATION_ADHERENCE')}
      </GraphTitle>
      {medications.sort(sortBy((m) => m.medication.name)).map((m) => (
        <View wrap={false} key={m.medicationId}>
          <MedicationTitle
            key={m.medicationId}
            medication={m}
            timeZone={timeZone}
            minPresenceAhead={100}
            style={medPadding}
          />
          <View style={styles.imgContainer}>
            <Image
              style={{ width: 580, height: 150 }}
              src={src[m.medicationId]}
            />
          </View>
        </View>
      ))}
    </View>
  );
};

export default ControllerAdherenceChart;
