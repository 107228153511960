import React, { useContext } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import RoundedBox from '../RoundedBox';
import StatusSectionTitle from '../StatusSectionTitle';
import Normal from '../Normal';
import GreyText from '../GreyText';

import colors from '../../utilities/colors';
import { displayDate, displayDateRange } from '../../utilities/dates';
import moment from 'moment';
import LocaleContext from '../../context/locale';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignContent: 'flex-start',
  },
  itemContainer: {
    flexDirection: 'column',
    flexGrow: 0,
    alignItems: 'center',
    marginRight: 10,
    width: 20,
  },
  centerAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bubbleContainer: {
    borderRadius: 50,
    width: 20,
    height: 20,
    marginBottom: 3,
  },
  bubbleText: {
    textAlign: 'center',
    color: '#FFF',
    fontSize: 10,
  },
});

const NONE = 0;
const SEVERE = 6;
const NOTICE = 1;

function dayColor(number) {
  if (typeof number !== 'number') return colors.lightestGrey; // null case, eg no data
  if (number === NONE) return colors.grey;
  if (number >= SEVERE) return colors.red;
  if (number >= NOTICE) return colors.purple;

  // we shouldn't ever get here, but just in case
  return colors.lightestGrey;
}

const RescueUsage = ({ events, date }) => {
  const locale = useContext(LocaleContext);

  const text = typeof events === 'number' ? events : '';

  return (
    <View style={styles.itemContainer}>
      <View
        style={{
          ...styles.bubbleContainer,
          ...styles.centerAlign,
          backgroundColor: dayColor(events),
        }}
      >
        <Normal style={styles.bubbleText}>{text}</Normal>
      </View>
      <View style={styles.centerAlign}>
        <GreyText style={{ fontSize: 8 }}>
          {displayDate(date, locale, 'ddd').toUpperCase()}
        </GreyText>
      </View>
    </View>
  );
};

const SevenDayRescue = ({ days }) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  const totalRescue = days.reduce(
    (tot, day) => tot + day.rescue.totalEvents,
    0
  );

  while (days.length < 7) {
    days.unshift({
      date: moment(days[0].date).subtract(1, 'day').toDate(),
      rescue: {
        totalEvents: null, // we don't want a number because we don't actually know
      },
    });
  }

  const dateRange = [days[0].date, days[days.length - 1].date];

  return (
    <RoundedBox color={colors.deepRed}>
      <StatusSectionTitle
        subText={`${t('LAST_NUM_DAYS', {
          number: 7,
        }).toLowerCase()} (${displayDateRange(dateRange, locale)})`}
      >
        {t('RESCUE_USE_TOTAL', { count: totalRescue })}
      </StatusSectionTitle>
      <View style={styles.row}>
        {days.map((day, i) => (
          <RescueUsage
            key={`rescue-day-${i}`}
            events={day.rescue.totalEvents}
            date={day.date}
          />
        ))}
      </View>
    </RoundedBox>
  );
};

export default SevenDayRescue;
