import { Font } from '@react-pdf/renderer';
import { registerFont } from 'canvas';
import { getFilePath } from './assets.js';

const fontPath = getFilePath('fonts');

const FONT_FAMILYS = [
  {
    family: 'CircularPro',
    normal: 'lineto-circular-pro-book.woff',
    bold: 'lineto-circular-pro-bold.woff',
  },
  {
    family: 'Ubuntu',
    normal: 'Ubuntu-R.ttf',
    bold: 'Ubuntu-B.ttf',
  },
  {
    family: 'NotoSansKR',
    normal: 'Noto-Sans-KR-Regular.ttf',
    bold: 'Noto-Sans-KR-Bold.ttf',
  },
  {
    family: 'NotoSansJP',
    normal: 'Noto-Sans-CJK-JP-Regular.ttf',
    bold: 'Noto-Sans-CJK-JP-Bold.ttf',
  }
];

FONT_FAMILYS.forEach(({ family, normal, bold }) => {
  const normalPath = fontPath(normal);
  const boldPath = fontPath(bold);

  Font.register({
    family,
    fonts: [
      {
        src: normalPath,
        fontWeight: 'normal',
        fontStyle: 'normal'
      },{
        src: boldPath,
        fontWeight: 'bold',
        fontStyle: 'oblique'
      }
    ]
  });

  if (!process.browser) {
    registerFont(normalPath, { family, weight: 'normal', style: 'normal' });
    registerFont(boldPath, { family, weight: 'bold', style: 'oblique' });
  }
});
