const { createCanvas, Image } = require('canvas');
const { timeFormatLocale } = require('d3');
const { getD3Locale } = require('../services/locales');
const { languageFont } = require('../utilities/text');

const LoggerService = require('../services/logger');
const { logger } = LoggerService;

const CANV_MULT = 2;

module.exports = class Chart {
  constructor({ data, i18n }) {
    const language = i18n.language;
    const [ lang ] = language.split('-');
    const fontFamily = languageFont(lang);

    this.locale = language;
    this.i18n = i18n;
    this.d3Locale = timeFormatLocale(getD3Locale(language));
    this.data = this.buildData(data);
    this.fontFamily = fontFamily;
  }

  buildData() {
    logger.warn && logger.warn({ msg: `override me for ${this.type}` });
    return null;
  }

  type = 'chartType';

  width = 0;
  height = 0;

  margins = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  };

  i18n = {
    t(a) {
      return a;
    }
  };

  renderer() {
    throw new Error(`override me: ${this.type}`);
  }

  render(window) {
    const self = this;

    return new Promise((resolve, reject) => {
      const svg = self.renderSVG(window.document.createElement('svg'));

      if (svg) {
        return resolve(svg);
      } else {
        const error = new Error(`Failed to create ${self.type} chart`);
        logger.error && logger.error(error);
        return reject(error);
      }
    }).then(svg => self.svgToPNG(svg.outerHTML));
  }

  isServer() {
    return typeof document === 'undefined';
  }

  createImg() {
    if (this.isServer()) {
      return new Image();
    } else {
      return document.createElement('img');
    }
  }

  createCanvas(width, height) {
    if (this.isServer()) {
      return createCanvas(width, height);
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      return canvas;
    }
  }

  toDataURI(svgString) {
    const DATA_URI_PREFIX = 'data:image/svg+xml;utf8,';

    // node-canvas looks for '<svg' to determine if it's an svg
    // but a web browser errors on an un-encoded src
    if (!this.isServer()) {
      svgString = encodeURIComponent(svgString);
    }

    return DATA_URI_PREFIX + svgString;
  }

  canvDim(n) {
    return n * CANV_MULT;
  }

  svgToPNG(svg) {
    // d3 has the wrong casing for these, which makes the img tag trigger an error
    svg = svg
      .replace(/clippath/g, 'clipPath')
      .replace(/textpath/g, 'textPath')
      .replace(/startoffset/g, 'startOffset');

    const { width, height, type } = this;

    const canvasWidth = this.canvDim(width);
    const canvasHeight = this.canvDim(height);

    const canvas = this.createCanvas(
      canvasWidth,
      canvasHeight,
      'SVG'
    );

    const ctx = canvas.getContext('2d');

    const img = this.createImg();
    const imgSource = this.toDataURI(svg);

    img.width = width;
    img.height = height;

    return new Promise(function(resolve, reject) {
      img.onload = function() {
        ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        resolve(canvas.toDataURL('image/png'));
      };

      img.onerror = function(evt, ...rest) {
        reject(new Error(`img.onerror for ${type}`));
      };

      img.src = imgSource;
    });
  }
};
