import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import RoundedBox from '../RoundedBox';
import StatusSectionTitle from '../StatusSectionTitle';
import MetricScore from '../MetricScore';
import GreyText from '../GreyText';

import colors from '../../utilities/colors';
import { precisionRound } from '../../utilities/text';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  text: {
    fontSize: 9,
  },
  maxWidth: {
    maxWidth: '45%',
  },
  fontCircular: {
    fontFamily: 'CircularPro',
  },
});

const RescueBaseline = ({ day = {}, style }) => {
  const { t } = useTranslation();
  const { rescue = {} } = day;
  const baseline30Days = rescue.baseline || 0;
  const baseline2Days = rescue.baselineAvgLastTwoDays || 0;

  return (
    <RoundedBox style={style} color={colors.orange}>
      <StatusSectionTitle>{t('RESCUE_BASELINE')}</StatusSectionTitle>
      <View style={styles.row}>
        <View style={styles.maxWidth}>
          <MetricScore>{precisionRound(baseline30Days, 2)}</MetricScore>
          <GreyText style={styles.text}>
            {t('LAST_NUM_DAYS', { number: 30 }).toLowerCase()}
          </GreyText>
        </View>
        <View style={{ padding: '0 2', alignItems: 'center' }}>
          <MetricScore style={styles.fontCircular}>→</MetricScore>
          <GreyText style={{ ...styles.text, ...styles.fontCircular }}>
            →
          </GreyText>
        </View>
        <View style={styles.maxWidth}>
          <MetricScore>{precisionRound(baseline2Days, 2)}</MetricScore>
          <GreyText style={styles.text}>
            {t('LAST_NUM_DAYS', { number: 2 }).toLowerCase()}
          </GreyText>
        </View>
      </View>
    </RoundedBox>
  );
};

export default RescueBaseline;
