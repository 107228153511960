import LoggerService from '../services/logger';
const { logger } = LoggerService;

export const fullName = ({ givenName, familyName }, locale = 'en-US') => {
  // order depends on locale (country?), eg. in Japan family name is first
  if (typeof locale !== 'string') {
    throw new Error('Invalid locale');
  }

  const country = locale.split('-')[1];

  switch (country) {
    case 'JP':
      return `${familyName} ${givenName}`;

    default:
      return `${givenName} ${familyName}`;
  }
};

export const capitalize = (word) => {
  if ('string' !== typeof word) {
    logger.warn &&
      logger.warn({ msg: `capitalize() called on non-string: ${word}` });
    return word;
  }

  return word[0].toUpperCase() + word.slice(1);
};

export const precisionRound = (number, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

export const languageFont = (lang) => {
  switch (lang) {
    case 'ja':
      return 'NotoSansJP';

    case 'ko':
      return 'NotoSansKR';

    case 'ru':
      return 'Ubuntu';

    case 'el':
      return 'Ubuntu';

    default:
      return 'CircularPro';
  }
};
