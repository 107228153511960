import React from 'react';
import { useTranslation } from 'react-i18next';

import RoundedBox from '../RoundedBox';
import StatusSectionTitle from '../StatusSectionTitle';
import MetricScore from '../MetricScore';

import colors from '../../utilities/colors';

const NightRescueUse = ({ count, numberDays = 30, style }) => {
  const { t } = useTranslation();

  return (
    <RoundedBox style={style} color={colors.purple}>
      <StatusSectionTitle
        subText={t('LAST_NUM_DAYS', { number: numberDays }).toLowerCase()}
      >
        {t('NIGHTTIME_RESUCE_USE')}
      </StatusSectionTitle>
      <MetricScore>{t('NUM_NIGHT', { count })}</MetricScore>
    </RoundedBox>
  );
};

export default NightRescueUse;
