import React, { useContext } from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import DocumentHeader from '../../components/DocumentHeader';
import PatientInfo from '../../components/PatientInfo';
import PatientPlan from '../../components/PatientPlan';
import HealthStatus from '../../components/HealthStatus';
import MedicationUsage from '../../components/MedicationUsage';
import LocaleContext from '../../context/locale';

import { displayDateRange, timeFrame, parseDate } from '../../utilities/dates';
import { sortByDate } from '../../utilities/arrays';
import { languageFont } from '../../utilities/text';

import {
  lastSyncDate,
  adherenceSummary,
  medsByType,
} from '../../utilities/medications';

const styles = StyleSheet.create({
  page: {
    padding: 18,
  },
  section: {
    margin: 5,
    padding: 10,
    flexGrow: 1,
  },
});

const Contents = ({
  data: {
    dailySummary,
    endDate,
    medications,
    patient,
    quiz,
    reportLength = 30,
  },
  charts,
}) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  const { disease, timeZone } = patient;
  const { controlStatus = {}, days: _days = [] } = dailySummary;

  // guard against 0 day report borking things. Ideally this is handled upstream
  // by either not rendering for this case, or by initializing a user's first userDay
  // on account creation
  if (_days.length === 0) {
    _days.push({ date: endDate });
  }

  const range = timeFrame({ dailySummary, endDate, reportLength });
  const meds = medsByType(medications);
  const sensors = medications.reduce((ary, med) => ary.concat(med.sensors), []);

  const title = t(`${disease.toUpperCase()}_INSIGHT_REPORT`);
  const dateRange = displayDateRange(range, locale);

  const days = _days
    .map((d) => {
      d.date = parseDate(d.date);
      return d;
    })
    .sort(sortByDate);

  // do we need to know requestor's country for page size?
  const [lang, country] = locale.split('-');
  const pageSize = ['US', 'CA'].includes(country) ? 'Letter' : 'A4';

  const fontFamily = languageFont(lang);

  return (
    <Document
      title={`${title}, ${dateRange}`}
      creator="Propeller Health"
      producer="Propeller Health"
    >
      <Page
        size={pageSize}
        orientation="portrait"
        style={{ ...styles.page, fontFamily }}
        wrap
      >
        <DocumentHeader firstLine={title} secondLine={dateRange} />
        <PatientInfo patient={patient} />
        <PatientPlan medications={meds} />
        <HealthStatus
          charts={charts}
          disease={disease}
          controlStatus={controlStatus}
          days={days.slice(-30)}
          quiz={quiz}
          adherence={adherenceSummary(medications, days)}
          lastSync={lastSyncDate(sensors, timeZone)}
        />
        <MedicationUsage
          numberDays={days.length}
          charts={charts}
          disease={disease}
          medications={meds}
          timeZone={timeZone}
        />
      </Page>
    </Document>
  );
};

export default Contents;
