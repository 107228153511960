import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import SevenDayRescue from '../SevenDayRescue';
import NightRescueUse from '../NightRescueUse';
import RescueBaseline from '../RescueBaseline';

const MARGIN = 2;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
    width: '50%',
  },
  rightMargin: {
    paddingRight: MARGIN,
  },
  leftMargin: {
    paddingLeft: MARGIN,
  },
  flexGrow: {
    flexGrow: 1,
  },
});

const leftColumn = { ...styles.column, ...styles.rightMargin };
const rightColumn = { ...styles.column, ...styles.leftMargin };

const COPDStatus = ({ days, rescueNights, style }) => {
  const totalDays = days.length;
  const last7Days = days.slice(-7);
  const mostRecentDay = days.slice(-1);

  return (
    <View style={{ ...styles.container, ...style }}>
      <SevenDayRescue days={last7Days} />
      <View style={styles.row}>
        <View style={leftColumn}>
          <NightRescueUse
            style={styles.flexGrow}
            numberDays={totalDays}
            count={rescueNights}
          />
        </View>
        <View style={rightColumn}>
          <RescueBaseline style={styles.flexGrow} day={mostRecentDay} />
        </View>
      </View>
    </View>
  );
};

export default COPDStatus;
