import React, { useContext } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import GreyText from '../GreyText';
import Normal from '../Normal';
import Bold from '../Bold';

import LocaleContext from '../../context/locale';

import { fullName, capitalize } from '../../utilities/text';
import { displayDate } from '../../utilities/dates';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  text: {
    fontSize: 15,
  },
});

const PatientInfo = ({ patient }) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  return (
    <View style={styles.container}>
      <Text>
        <Bold style={styles.text}>{fullName(patient, locale)}</Bold>
        <GreyText style={styles.text}> | </GreyText>
        {!patient.isFakeBirthDate && (
          <Normal style={styles.text}>
            {t('AGE_YEARS_OLD', { age: patient.age })},{' '}
            {displayDate(patient.birthDate, locale, 'll')}
          </Normal>
        )}
        <GreyText style={styles.text}> | </GreyText>
        <Normal style={styles.text}>
          {capitalize(t(patient.disease.toUpperCase()))}
        </Normal>
      </Text>
    </View>
  );
};

export default PatientInfo;
