import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { node, shape } from 'prop-types';
import Normal from '../Normal';

import colors from '../../utilities/colors';

const ownStyle = StyleSheet.create({
  color: colors.grey,
});

const GreyText = ({ style, children }) => (
  <Normal style={{ ...ownStyle, ...style }}>{children}</Normal>
);

GreyText.propTypes = {
  children: node.isRequired,
  style: shape({}),
};

GreyText.defaultProps = {
  style: {},
};

export default GreyText;
