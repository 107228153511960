const Chart = require('../chartBase');

const moment = require('moment');

const {
  select: d3Select,
  axisBottom,
  timeMonth
} = require('d3');

const colors = require('../../utilities/colors');
const { tzDate } = require('../../utilities/dates');

const V_TEXT_SPACE = 15;

module.exports = class TimeSeries extends Chart {
  constructor(config) {
    super(config);
    this.timeZone = config.data.patient.timeZone;
    this.dateJoined = tzDate(config.data.patient.createdDate, this.timeZone);
  }

  type = 'timeSeries';

  width = 970;

  height = 250;

  margins = {
    top: 5,
    bottom: 40,
    left: 30,
    right: 20
  };

  adjustAxis(axis) {
    return g => {
      axis(g);
      g.select('.domain').remove();
      g.selectAll('.tick line')
        .attr('stroke', colors.lightestGrey);
      g.selectAll('text')
        .attr('shape-rendering', 'crispEdges')
        .attr('fill', colors.text)
        .attr('stroke', 'none');
    };
  }

  buildFrame(
    node,
    { xScale, yAxis, xAxis, includeMonths }
  ) {
    const { adjustAxis, width, height, margins, d3Locale, locale } = this;

    const labelOffset = height - margins.bottom;
    const chartHeight = labelOffset - margins.top;
    const chartWidth = width - margins.left - margins.right;

    const svg = d3Select(node)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
      .attr('font-family', `"${this.fontFamily}", sans-serif`);

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'chart-mask')
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight);

    svg
      .append('g')
      .attr('transform', `translate(${margins.left},${margins.top})`)
      .call(adjustAxis(yAxis));

    svg
      .append('g')
      .attr('transform', `translate(${margins.left},${labelOffset})`)
      .call(adjustAxis(xAxis));

    if (includeMonths) {
      const formatMonth = d3Locale.format('%b');

      const monthAxis = axisBottom()
        .ticks(timeMonth)
        .tickFormat(d => formatMonth(d).toLocaleUpperCase(locale))
        .tickSize(0)
        .tickPadding(25)
        .scale(xScale);

      svg
        .append('g')
        .attr('transform', `translate(${margins.left},${labelOffset})`)
        .call(adjustAxis(monthAxis));
    }

    // build a border
    svg
      .append('rect')
      .attr('class', 'chart-border')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('x', margins.left)
      .attr('y', margins.top)
      .attr('stroke-width', 1)
      .attr('stroke', colors.text)
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('fill-opacity', 0);

    return svg;
  }

  // if a med has sensors and we've never heard from them, we should call that out
  // if a med hasn't synced in the time frame, we should call that out
  // if we have synced but have no usages within the frame, we should call it out rather than looking broken
  // anything else?
  appendBounds(node, { xScale, lastSync, firstDate }) {
    const { width, height, margins, i18n, dateJoined } = this;

    const chartHeight = height - margins.top - margins.bottom;
    const chartWidth = width - margins.left - margins.right;
    const labelY = chartHeight - 5;

    if (lastSync) {
      const syncPosition = xScale(lastSync);
      const syncWidth = chartWidth - syncPosition;

      if (syncWidth > 0) {
        // draw section showing last sync
        // they have synced withing our time frame
        node
          .append('rect')
          .attr('height', chartHeight)
          .attr('width', syncWidth)
          .attr('transform', `translate(${syncPosition}, 0)`)
          .attr('fill', colors.lightGrey)
          .attr('fill-opacity', '0.4');

        node
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', chartHeight)
          .attr('y2', 0)
          .attr('transform', `translate(${syncPosition}, 0)`)
          .attr('stroke-width', 2)
          .attr('stroke', colors.darkGrey)
          .attr('fill', 'none');

        if (syncPosition > 0 && syncWidth > V_TEXT_SPACE) {
          node
            .append('text')
            .text(i18n.t('SENSOR_INACTIVE').toUpperCase())
            .attr(
              'transform',
              `translate(${syncPosition + 13}, ${labelY}) rotate(-90)`
            )
            .attr('fill', colors.darkGrey)
            .attr('stroke', 'none')
            .style('font-size', '12px')
            .style('font-weight', 'bold');
        }
      }
    }

    if (dateJoined) {
      const prehistoryPosition = xScale(dateJoined);

      if (prehistoryPosition > 0) {
        node
          .append('rect')
          .attr('height', chartHeight - 1)
          .attr('width', prehistoryPosition - 1)
          .attr('fill', colors.lightBlue)
          .attr('fill-opacity', '1')
          .attr('transform', 'translate(1,1)');

        node
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', chartHeight)
          .attr('y2', 0)
          .attr('transform', `translate(${prehistoryPosition},0)`)
          .attr('stroke-width', 2)
          .attr('stroke', colors.blue)
          .attr('fill', 'none');

        if (prehistoryPosition > V_TEXT_SPACE) {
          node
            .append('text')
            .text(i18n.t('PATIENT_JOINED').toUpperCase())
            .attr('fill', colors.darkGrey)
            .attr('stroke', 'none')
            .attr(
              'transform',
              `translate(${prehistoryPosition - 5}, ${labelY}) rotate(-90)`
            )
            .style('font-size', '12px')
            .style('font-weight', 'bold');
        }
      }

      if (firstDate) {
        const firstDatePosition = xScale(firstDate);

        if (firstDatePosition > 0 && firstDatePosition - prehistoryPosition > 1) {
          node
            .append('line')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', chartHeight)
            .attr('y2', 0)
            .attr('transform', `translate(${firstDatePosition},0)`)
            .attr('stroke-width', 2)
            .attr('stroke', colors.blue)
            .attr('fill', 'none');

          if (firstDatePosition - prehistoryPosition > V_TEXT_SPACE) {
            node
              .append('text')
              .text(i18n.t('MED_ADDED').toUpperCase())
              .attr('fill', colors.darkGrey)
              .attr('stroke', 'none')
              .attr(
                'transform',
                `translate(${firstDatePosition - 5}, ${labelY}) rotate(-90)`
              )
              .style('font-size', '12px')
              .style('font-weight', 'bold');
          }
        }
      }
    }

    return node;
  }

  appendOverlay(node, { xScale, lastSync, firstDate, totalUsages = 1 }) {
    const { width, height, margins, locale, data } = this;
    const { dateRange: [start, end] } = data;

    const mStart = moment(start);
    mStart.locale(locale);

    const overlayDefaults = {
      height: height - margins.top - margins.bottom,
      width: width - margins.left - margins.right,
      text: '',
      timeFrame: mStart.from(end, true).toLocaleUpperCase(locale),
      fill: colors.white,
      opacity: '0'
    };

    if (lastSync) {
      const syncPosition = xScale(lastSync);

      if (syncPosition <= 0) {
        // console.log('no sync over period');
        this.renderOverlay(node, {
          ...overlayDefaults,
          text: 'NO_SYNC_IN',
          opacity: '0'
        });
      } else if (
        lastSync.isAfter(moment(end).subtract(1, 'day')) &&
        moment(firstDate).isBefore(moment(start)) &&
        totalUsages === 0
      ) {
        // console.log('we have syncs but no events over period');
        this.renderOverlay(node, {
          ...overlayDefaults,
          text: 'NO_USES_IN',
          fill: colors.mauve,
          opacity: '0.4'
        });
      } else {
        // console.log('some other case...');
      }
    } else if (totalUsages === 0) {
      // console.log('no sync, no events');
      this.renderOverlay(node, {
        ...overlayDefaults,
        text: 'NO_DATA',
        fill: colors.mauve,
        opacity: '0.4'
      });
    }
  }

  renderOverlay(node, { height, width, text, timeFrame, fill, opacity }) {
    const { i18n, locale } = this;
    node
      .append('rect')
      .attr('height', height)
      .attr('width', width)
      .attr('fill', fill)
      .attr('fill-opacity', opacity);

    node
      .append('text')
      .text(i18n.t(text, { timeFrame }).toLocaleUpperCase(locale))
      .attr('transform', `translate(${width / 2}, ${height / 2 + 6})`)
      .attr('fill', colors.darkGrey)
      .attr('stroke', 'none')
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .style('vertical-align', 'middle');
  }
};
