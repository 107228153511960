import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import StatusSectionTitle from '../StatusSectionTitle';
import MetricScore from '../MetricScore';

import { displayDate } from '../../utilities/dates';
import LocaleContext from '../../context/locale';

const TEST_FOR_DISEASE = {
  asthma: {
    title: 'ASTHMA_CONTROL_TEST',
    textValue: (score, type) => {
      const ranges = {
        child: [
          {
            value: 12,
            text: 'VERY_POORLY_CONTROLLED',
          },
          {
            value: 19,
            text: 'NOT_WELL_CONTROLLED',
          },
          {
            value: 27,
            text: 'WELL_CONTROLLED',
          },
        ],
        adult: [
          {
            value: 15,
            text: 'VERY_POORLY_CONTROLLED',
          },
          {
            value: 19,
            text: 'NOT_WELL_CONTROLLED',
          },
          {
            value: 25,
            text: 'WELL_CONTROLLED',
          },
        ],
      };

      return ranges[type].filter((r) => score <= r.value)[0].text;
    },
  },
  copd: {
    title: 'COPD_ASSESSMENT_TEST',
    textValue: (score) => {
      const ranges = [
        {
          value: 9,
          text: 'LOW_IMPACT',
        },
        {
          value: 20,
          text: 'MEDIUM_IMPACT',
        },
        {
          value: 30,
          text: 'HIGH_IMPACT',
        },
        {
          value: 40,
          text: 'VERY_HIGH_IMPACT',
        },
      ];

      return ranges.filter((r) => score <= r.value)[0].text;
    },
  },
};

const QuizScore = ({ quiz, disease }) => {
  const { t } = useTranslation();
  const locale = useContext(LocaleContext);

  const config = TEST_FOR_DISEASE[disease];

  return (
    <React.Fragment>
      <StatusSectionTitle
        subText={
          quiz.date
            ? t('TAKEN_ON_DATE', { date: displayDate(quiz.date, locale, 'll') })
            : t('NOT_TAKEN')
        }
      >
        {t(config.title)}
      </StatusSectionTitle>
      <MetricScore>
        {quiz.score
          ? `${quiz.score} | ${t(config.textValue(quiz.score, quiz.type))}`
          : t('N_A')}
      </MetricScore>
    </React.Fragment>
  );
};

export default QuizScore;
