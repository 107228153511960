import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import { pdf } from '@react-pdf/renderer';

import ChartRenderer from '../ChartRenderer';
import SideBar from '../SideBar';

import PatientSummary from '../../../src/documents/PatientSummary';

import renderCharts from '../../../src/charts';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportId: '',
      json: {},
      blobUrl: null,
      loading: false,
    };

    this.handleLocaleChange = this.handleLocaleChange.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
  }

  render() {
    const { i18n } = this.props;
    const { reportId, json, blobUrl, loading } = this.state;

    return (
      <>
        <Navbar bg="dark" variant="dark" sticky="top">
          <Navbar.Brand className="session-info">
            PH-pdf-report demo/test app
          </Navbar.Brand>
        </Navbar>
        <Container fluid>
          <Row>
            <SideBar
              language={i18n.language}
              currentReport={reportId}
              localeOnChange={this.handleLocaleChange}
              reportOnChange={this.handleDataChange}
              renderReport={this.renderPDF}
              loading={loading}
            />
            <Col md={10} as="main">
              <ChartRenderer loading={loading} json={json} blobUrl={blobUrl} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  handleLocaleChange(e) {
    const { i18n } = this.props;
    const { value } = e.target;

    i18n.changeLanguage(value);
  }

  handleDataChange(e) {
    const { value } = e.target;

    this.setState({ reportId: value });
  }

  async renderPDF() {
    const { i18n } = this.props;
    const { reportId, blobUrl } = this.state;

    if (!reportId) return;

    this.setState({ loading: true });

    if (blobUrl) {
      URL.revokeObjectURL(blobUrl);
      this.setState(() => ({ blobUrl: null }));
    }

    try {
      let data = await fetch(`/data/${reportId}.json`);
      data = await data.json();
      console.log(data);
      data.locale = i18n.language;
      const charts = await renderCharts({ data, window, i18n });

      const blob = await pdf(
        <PatientSummary
          data={data}
          charts={charts}
          i18n={i18n}
          logger={window.console}
        />
      ).toBlob();

      this.setState(() => ({
        json: data,
        blobUrl: URL.createObjectURL(blob),
        loading: false,
      }));
    } catch (err) {
      console.error(err);

      this.reset(err);
    }
  }

  reset(error) {
    if (this.state.blobUrl) {
      URL.revokeObjectURL(this.state.blobUrl);
    }

    this.setState({
      reportId: '',
      json: {},
      blobUrl: null,
      loading: false,
      error,
    });
  }
}

export default withTranslation()(Main);
