const moment = require('moment');

const d3_caES = require('d3-time-format/locale/ca-ES');
const d3_daDK = require('d3-time-format/locale/da-DK');
const d3_deCH = require('d3-time-format/locale/de-CH');
const d3_deDE = require('d3-time-format/locale/de-DE');
const d3_csCZ = require('d3-time-format/locale/cs-CZ');
const d3_enCA = require('d3-time-format/locale/en-CA');
const d3_enGB = require('d3-time-format/locale/en-GB');
const d3_enUS = require('d3-time-format/locale/en-US');
const d3_esES = require('d3-time-format/locale/es-ES');
const d3_esMX = require('d3-time-format/locale/es-MX');
const d3_fiFI = require('d3-time-format/locale/fi-FI');
const d3_frCA = require('d3-time-format/locale/fr-CA');
const d3_frFR = require('d3-time-format/locale/fr-FR');
const d3_itIT = require('d3-time-format/locale/it-IT');
const d3_jaJP = require('d3-time-format/locale/ja-JP');
const d3_koKR = require('d3-time-format/locale/ko-KR');
const d3_nlNL = require('d3-time-format/locale/nl-NL');
const d3_nbNO = require('d3-time-format/locale/nb-NO');
const d3_ruRU = require('d3-time-format/locale/ru-RU');
const d3_svSE = require('d3-time-format/locale/sv-SE');

// adapted from d3's pt-BR and moment's pt
const d3_ptPT = {
  dateTime: '%A, %e de %B de %Y, %X',
  date: '%d/%m/%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
  shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  shortMonths: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
};

const d3_elGR = {
  dateTime: '%A %e %B %Y, %X',
  date: '%d/%m/%Y',
  time: '%I:%M:%S %p',
  periods: ['π.μ.', 'μ.μ.'],
  days: [
    'Κυριακή',
    'Δευτέρα',
    'Τρίτη',
    'Τετάρτη',
    'Πέμπτη',
    'Παρασκευή',
    'Σάββατο',
  ],
  shortDays: ['Κυρ', 'Δευ', 'Τρί', 'Τετ', 'Πέμ', 'Παρ', 'Σάβ'],
  months: [
    'Ιανουαρίου',
    'Φεβρουαρίου',
    'Μαρτίου',
    'Απριλίου',
    'Μαΐου',
    'Ιουνίου',
    'Ιουλίου',
    'Αυγούστου',
    'Σεπτεμβρίου',
    'Οκτωβρίου',
    'Νοεμβρίου',
    'Δεκεμβρίου',
  ],
  shortMonths: [
    'Ιαν',
    'Φεβ',
    'Μαρ',
    'Απρ',
    'Μαΐ',
    'Ιουν',
    'Ιουλ',
    'Αυγ',
    'Σεπ',
    'Οκτ',
    'Νοε',
    'Δεκ',
  ],
};

const d3_frBE = {
  dateTime: '%A, le %e %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ],
  shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  shortMonths: [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.',
  ],
};

const d3_nlBE = {
  dateTime: '%a %e %B %Y %T',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag',
  ],
  shortDays: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  months: [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ],
  shortMonths: [
    'jan',
    'feb',
    'mrt',
    'apr',
    'mei',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
  ],
};

const d3_svFI = {
  dateTime: '%A, %-d. %Bta %Y klo %X',
  date: '%-d.%-m.%Y',
  time: '%H:%M:%S',
  periods: ['fm', 'em'],
  days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
  shortDays: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  months: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ],
};

const DEFAULT_LOCALE = 'en-US';

// [locale code, d3 locale data, language fallbacks]
const LOCALE_DATA = [
  ['ca-ES', d3_caES, undefined],
  ['cs-CZ', d3_csCZ, undefined],
  ['da-DK', d3_daDK, undefined],
  ['de-AT', d3_deDE, ['de-DE']],
  ['de-CH', d3_deCH, ['de-DE']],
  ['de-DE', d3_deDE, undefined],
  ['el-GR', d3_elGR, undefined],
  ['en-AU', d3_enGB, ['en-GB']],
  ['en-CA', d3_enCA, ['en-US']],
  ['en-GB', d3_enGB, undefined],
  ['en-IE', d3_enGB, ['en-GB']],
  ['en-US', d3_enUS, undefined],
  ['es-AR', d3_esMX, undefined],
  ['es-ES', d3_esES, undefined],
  ['es-MX', d3_esMX, undefined],
  ['es-US', d3_esMX, undefined],
  ['fi-FI', d3_fiFI, undefined],
  ['fr-CA', d3_frCA, undefined],
  ['fr-CH', d3_frFR, ['fr-FR']],
  ['fr-BE', d3_frBE, ['fr-FR']],
  ['fr-FR', d3_frFR, undefined],
  ['it-CH', d3_itIT, ['it-IT']],
  ['it-IT', d3_itIT, undefined],
  ['ja-JP', d3_jaJP, undefined],
  ['ko-KR', d3_koKR, undefined],
  ['nl-BE', d3_nlBE, ['nl-NL']],
  ['nl-NL', d3_nlNL, undefined],
  ['nb-NO', d3_nbNO, undefined],
  ['pt-PT', d3_ptPT, undefined],
  ['ru-RU', d3_ruRU, undefined],
  ['sv-FI', d3_svFI, ['sv-SE']],
  ['sv-SE', d3_svSE, undefined],
];

function hourFormatMomentToD3(lng) {
  const fmt = moment().locale(lng).localeData().longDateFormat('LT');

  let d3HourFormat = fmt
    .replace(/\s/g, '')
    .replace(/H{2}/, '%H') // 24 hour time, 0 padded
    .replace(/(?<!%)H/, '%-H') // 24 hour time, not padded (excludes '%H')
    .replace(/h{2}/, '%I') // 12 hour time, 0 padded
    .replace(/(?<!%)h/, '%-I'); // 12 hour time, not padded

  if (d3HourFormat.match(/a/i)) {
    d3HourFormat = d3HourFormat
      .replace(/:|m/gi, '') // strip minutes
      .replace(/a/i, '%p'); // AM / PM
  } else {
    d3HourFormat = d3HourFormat
      .replace(/m{2}/, '%M') // minutes, 0 padded
      .replace(/(?<!%)m/, '%-M'); // minute, not padded (excludes '%M')
  }

  return d3HourFormat;
}

/**
 * build out an object like
 *   {
 *     'en-US': {
 *       d3Locale: {},
 *       fallbacks: [],
 *       d3HourFormat: ''
 *     },
 *     ...
 *   }
 *
 */
const LOCALES = LOCALE_DATA.reduce(
  (accumulator, [lng, d3Locale, fallbacks]) => {
    accumulator[lng] = {
      d3Locale,
      fallbacks,
      d3HourFormat: hourFormatMomentToD3(lng),
    };

    return accumulator;
  },
  {}
);

const supportedLanguages = Object.keys(LOCALES);

const fallbacks = LOCALE_DATA.reduce(
  (accumulator, ld) => {
    if (ld[2]) {
      accumulator[ld[0]] = ld[2];
    }

    return accumulator;
  },
  { default: [DEFAULT_LOCALE] }
);

function getLocaleProp(prop) {
  return function (locale) {
    if (LOCALES[locale] && LOCALES[locale][prop]) {
      return LOCALES[locale][prop];
    }

    return LOCALES[DEFAULT_LOCALE][prop];
  };
}

module.exports.getD3Locale = getLocaleProp('d3Locale');
module.exports.getD3HourFormat = getLocaleProp('d3HourFormat');

module.exports.i18nConfig = {
  fallbackLng: fallbacks,
  supportedLanguages,
  preload: supportedLanguages,
  load: 'currentOnly',
  initImmediate: false,
  interpolation: {
    escapeValue: false,
  },
  ns: ['patient_report'],
  defaultNS: 'patient_report',
  react: {
    useSuspense: false,
  },
};

module.exports.supportedLanguages = supportedLanguages;
