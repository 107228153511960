import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';

import Bold from '../Bold';

const ownStyle = StyleSheet.create({
  fontSize: 14,
});

const MetricScore = ({ style, children }) => (
  <Bold style={{ ...ownStyle, ...style }}>{children}</Bold>
);

export default MetricScore;
